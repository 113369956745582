<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col xl="4" md="6">
        <statistic-info-card @fetchData="getStatisticData" :data="data" :years="projectsYear" />
      </b-col>
      <b-col xl="8" md="6">
        <statistic-features-card :data="data" :year="getYear" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="4">
        <b-row class="match-height">
          <b-col lg="12" md="6">
            <statistic-earning-amount-card :data="data" />
          </b-col>
        </b-row>
      </b-col>

      <!-- Revenue Report Card -->
      <b-col lg="8">
        <statistic-revenue-report :expensesTotal="exploitationExpenses" :data="data.report" />
      </b-col>
      <!--/ Revenue Report Card -->
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

import StatisticInfoCard from "./StatisticInfoCard.vue";
import StatisticFeaturesCard from "./StatisticFeaturesCard.vue";
import StatisticEarningAmountCard from "./StatisticEarningAmountCard.vue";
import StatisticRevenueReport from "./StatisticRevenueReport.vue";

import store from "@/store";
export default {
  name: "Statistic",
  components: {
    BRow,
    BCol,

    StatisticInfoCard,
    StatisticFeaturesCard,
    StatisticEarningAmountCard,
    StatisticRevenueReport,
  },
  data() {
    return {
      currYear: new Date().getFullYear().toString(),
      data: {
        project_expenses: 0,
        admin_expenses: 0,
        won_amount: 0,
        lost_amount: 0,
        profit: 0,
        revenue: 0,
        report: { data: {}},
      },
      projectsYear: [],
    };
  },
  computed: {
    getYear() {
      return this.currYear;
    },
    exploitationExpenses() {
      return this.data.project_expenses + this.data.admin_expenses
    },
  },
  created() {
    this.getAllProjectsYears();
    this.getStatisticData(this.currYear);
  },
  methods: {
    getAllProjectsYears() {
      this.$http.get("properties/projects", {
        params: { props: ["years"] },
      }).then(({ data }) => this.projectsYear = data.resource?.years)
      .catch(() => {
        this.$toast({
            component: ToastificationContent,
            props: {
              title: "Erreur de récupération des années des projets",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
      });
    },
    // get all project's statistic (profit, expenses, win projects, lost projects, etc.)
    getStatisticData(year) {
      store
        .dispatch("fundHistory/getStatistic", { year: year })
        .then(({ data }) => {
          this.data = data.resource;
          this.currYear = year;
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Erreur de récupération des données de statistic",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
