<template>
  <b-card class="earnings-card">
    <b-row>
      <b-col cols="6">
        <b-card-title class="mb-1"> Profit </b-card-title>
        <!-- <div class="font-small-2">XOF</div> -->
        <h5 class="mb-1">{{ amountToCurrency(data.profit) }}</h5>
        <b-card-text class="text-muted font-small-2">
          <span class="font-weight-bolder">...</span
          ><span> ...</span>
        </b-card-text>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { amountToCurrency } from "@/utils/formatAmount";
import { BCard, BRow, BCol, BCardTitle, BCardText } from "bootstrap-vue";
export default {
  name: "StatisticEarningAmountCard",
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {
    amountToCurrency,
  }
};
</script>
