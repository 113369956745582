<template>
  <b-card
    v-if="data"
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Informations générales</b-card-title>
      <b-card-text class="font-small-4 mr-25 mb-0">
        Année sélectionnée : <b>{{ year }}</b>
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <!-- won project -->
        <b-col
          xl="3"
          sm="6"
          class="mb-2 mb-xl-0"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-primary"
              >
                <feather-icon
                  size="24"
                  icon="TrendingUpIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h5 class="font-weight-bolder mb-0">
                {{ amountToCurrency(data.won_amount) }}
              </h5>
              <b-card-text class="font-small-3 mb-0">
                Projets Gagnés
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <!-- lost project -->
        <!-- <b-col
          xl="3"
          sm="6"
          class="mb-2 mb-xl-0"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-danger"
              >
                <feather-icon
                  size="24"
                  icon="TrendingDownIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h5 class="font-weight-bolder mb-0">
                {{ amountToCurrency(data.lost_amount) }}
              </h5>
              <b-card-text class="font-small-3 mb-0">
                Projets Perdus
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col> -->

        <!-- projects expenses -->
        <b-col
          xl="3"
          sm="6"
          class="mb-2 mb-xl-0"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-info"
              >
                <feather-icon
                  size="24"
                  icon="ShoppingCartIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h5 class="font-weight-bolder mb-0">
                {{ amountToCurrency(data.project_expenses) }}
              </h5>
              <b-card-text class="font-small-3 mb-0">
                Dépenses Projets
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <!-- admins expenses -->
        <b-col
          xl="3"
          sm="6"
          class="mb-2 mb-xl-0"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-success"
              >
                <feather-icon
                  size="24"
                  icon="DollarSignIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h5 class="font-weight-bolder mb-0">
                {{ amountToCurrency(data.admin_expenses) }}
              </h5>
              <b-card-text class="font-small-3 mb-0">
                Dépenses Admins
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'
import { amountToCurrency } from "@/utils/formatAmount";

export default {
  name: "StatisticFeaturesCard",
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    year: {
      type: String,
      require: true,
    },
  },
  methods: {
    amountToCurrency,
  }
}
</script>
